import { v4 as uuidv4 } from 'uuid'
import CryptoJS from 'crypto-js'
import socketIO from 'socket.io-client'

let _socket

export const socketConnectionFn = (url, eventCallbacks) => {
  _socket = socketIO(`${url}`, {
    transports: ['websocket', 'polling']
  })

  // 連線成功
  _socket.on('connect', () => {
    if (eventCallbacks.connectStatus) {
      eventCallbacks.connectStatus(true)
    }
  })

  // user_event
  _socket.on('user_event', (info) => {
    if (eventCallbacks.userEvent) {
      eventCallbacks.userEvent(info)
    }
  })

  // 分配事件
  _socket.on('assigned', (info) => {
    if (eventCallbacks.assigned) {
      eventCallbacks.assigned(info)
    }
  })

  // 訊息
  _socket.on('message', (info) => {
    if (eventCallbacks.message) {
      eventCallbacks.message(info)
    }
  })

  // 結束會話
  _socket.on('finished', (info) => {
    if (eventCallbacks.finished) {
      eventCallbacks.finished(info)
    }
  })

  // 邀請評價
  _socket.on('invite_rating', (info) => {
    if (eventCallbacks.inviteRating) {
      eventCallbacks.inviteRating(info)
    }
  })

  // 對話驗證
  _socket.on('anti_verification', (info) => {
    if (eventCallbacks.antiVerification) {
      eventCallbacks.antiVerification(info)
    }
  })

  // 詢前表單
  _socket.on('info_collection', (info) => {
    if (eventCallbacks.infoCollection) {
      eventCallbacks.infoCollection(info)
    }
  })

  // 撤回訊息
  _socket.on('recall', (info) => {
    if (eventCallbacks.recall) {
      eventCallbacks.recall(info)
    }
  })

  // error
  _socket.on('error', (info) => {
    if (eventCallbacks.error) {
      eventCallbacks.error(info)
    }
  })

  // 斷開連線
  _socket.on('disconnect', () => {
    if (eventCallbacks.connectStatus) {
      eventCallbacks.connectStatus(false)
    }
  })
}

// 斷開連線
export const socketDisconnectFn = () => {
  if (_socket) {
    _socket.disconnect()
  }
}

// 發送 newConversation
/**
 * @param {string} requestId
 * @param {string} secret
 * @param {Object} reqConversation (agentId、channelUrl、departmentId)
 * @param {Object} infoCollection  信息收集表單
 * @param {Object} antiVerificationToken 防刷驗證
 * @returns
 */
export const newConversationFn = (requestId, secret, reqConversation, infoCollection, antiVerificationToken) => {
  const signature = generateSignature(secret, reqConversation)
  const requestData = buildRequestData(
    requestId,
    signature,
    { reqConversation },
    infoCollection ? { infoCollection } : {},
    antiVerificationToken ? { antiVerificationToken } : {}
  )

  if (_socket) {
    _socket.emit('newConversation', requestData)
  }
}

// 發送 message
export const sendMessageFn = (secret, message) => {
  const signature = generateSignature(secret, message)
  const requestData = buildRequestData(null, signature, { message })

  const resultPromise = new Promise((resolve, reject) => {
    addToQueue('message', requestData, (error, response) => {
      if (error) {
        reject(response)
      } else {
        resolve(response)
      }
    })
  })

  return { requestData, resultPromise }
}

// 發送 typing
export const sendTypingFn = (message) => {
  const requestData = buildRequestData(null, null, { message })

  if (_socket) {
    _socket.emit('typing', requestData)
  }
}

// 發送 readMsg 消息已讀
export const readMsgFn = (info) => {
  if (_socket) {
    _socket.emit('readMsg', info)
  }
}

// 發送 delivered 消息抵達
export const MsgDeliveredFn = (info) => {
  if (_socket) {
    _socket.emit('delivered', info)
  }
}

/**
 * 建構數據
 * @param {*} secret
 * @param {*} type
 * @param {*} data
 * @returns
 */
export const buildRequestData = (requestId, signature, ...payloads) => {
  return {
    requestId: requestId || uuidv4(),
    signature,
    ...payloads.reduce((acc, curr) => ({ ...acc, ...curr }), {})
  }
}

// 儲存所有的請求
const queues = {
  message: [], // 消息發送對列
  edit: [], // 消息編輯對列
  readMsg: [] // 消息已讀對列
}
// 是否正在處理對列
const isProcessing = {
  message: false,
  edit: false,
  readMsg: false
}
const timeoutDuration = 3000 // 超過時間（毫秒）

// 添加請求到對列
const addToQueue = (eventType, requestData, callback, maxRetries = 3) => {
  if (!queues[eventType]) {
    console.error(`Unsupported event type: ${eventType}`)
    return
  }
  queues[eventType].push({ requestData, callback, maxRetries, currentRetry: 0 })
  processQueue(eventType)
}

// 處理對列中請求
const processQueue = async (eventType) => {
  if (isProcessing[eventType] || queues[eventType].length === 0) {
    return // 如果正在對列或對列為空，直接返回
  }
  isProcessing[eventType] = true

  while (queues[eventType].length > 0) {
    const batch = queues[eventType].splice(0, 3) // 處理3個資料

    // 處理batch中的資料
    for (const { requestData, callback, maxRetries, currentRetry } of batch) {
      try {
        await handleEmitAndRetry(eventType, requestData, callback, maxRetries, currentRetry)
      } catch (error) {
        console.error('請求處理失敗', error)
      }
    }
  }

  isProcessing[eventType] = false // 處理完所有對列後，標記不再處理
}

// 處理emit事件跟重試
const handleEmitAndRetry = (eventType, requestData, callback, maxRetries, currentRetry) => {
  const send = () => {
    if (!_socket) {
      callback(error, 'Socket connection not available.')
      return
    }

    let isAckReceived = false

    _socket.emit(eventType, requestData, (ackResponse) => {
      if (isAckReceived) { return } // 避免重复处理
      isAckReceived = true
      clearTimeout(timeout)

      if (ackResponse) {
        if (ackResponse.code === 200) {
          callback(null, ackResponse)
          // 成功回调
        } else {
          console.error(`Error ACK for ${eventType}, retrying... msg:`, ackResponse.msg)
          retry()
        }
      } else {
        console.error('Empty ACK response.')
        callback(error, 'Empty ACK response.')
      }
    })

    const timeout = setTimeout(() => {
      if (!isAckReceived) {
        retry()
      }
    }, timeoutDuration) // 超時時間
  }

  const retry = () => {
    if (currentRetry < maxRetries) {
      handleEmitAndRetry(eventType, requestData, callback, maxRetries, currentRetry + 1)
    } else {
      callback(new Error('error'), requestData)
    }
  }

  send() // 初次发送
}

/**
 * 生成 SHA256 加密的签名
 * @param {string} secret - 密钥
 * @param {Object} data - 请求的具体数据 (如 message 或 reqConversation)
 * @returns {string} - 生成的 SHA256 签名
 */
export const generateSignature = (secret, data) => {
  const timestamp = Math.floor(Date.now() / 1000) // 当前时间戳（秒）
  const dataString = JSON.stringify(data) // 将数据转换为字符串
  const rawSignature = dataString + timestamp + secret // 拼接 key、时间戳和数据
  const hash = CryptoJS.SHA256(rawSignature).toString() // 生成 SHA256 hash
  return hash
}
